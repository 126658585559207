.loginPage {
    background: #FFCB05;
    .leftBlock {
        max-width: 55%;
        background: #FFCB05;
        box-shadow: none;
        img {
            object-fit: cover;
            height: 100vh;
            object-position: left 10%;
        }
    }
    .loginButton {
        background: #00A651;
        font-size: 1.125rem;
        &:hover {
            background: #00A651;
        }
    }
}
.logo {
    width: 200px;
    margin: 0 auto 4rem;
}

.loginpageform {
    input {
        background-color:#fff;
        padding-left: 30px;
  
    }
}
.viewIcon {
    background-color: #e8f0fe;
}
