.newVedioButton,
.addVideoButton,
.backButton,
.updateButton,.submitBtn {
    background-color: #00A651;
    color: #ffffff;
    // margin-bottom: 1rem;
    // padding: 0.35em 1em;
    // max-height: 40px;
    &:hover {
        background: #00A651;
    }
}
.backButton {
    margin-bottom: 1rem;
}
.buttonGroup {
    display: flex;
    align-items: center;
    .filterForm {
        max-height: 40px;
    }
}
// Data Table Container
.dataTable {
    max-width: 100% !important ;
    padding: 0;
}

// Video cards
.videoCard {
    border-radius: 0;
    border: 5px solid #FFCB05;
    .pauseIcon {
        position: absolute;
        z-index: 2;
        bottom: 10%;
        right: 7.5%;
        background-color: #0000007d;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    .hoverCardContent {
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    &:hover {
        .hoverCardContent {
            opacity: 1;
        }
        .cardImage {
            &::after {
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(22, 28, 36, 0.72);
            }
        }
        .pauseIcon {
            opacity: 0;
            color: #000000;
            background-color: #ffff00fd;
        }
    }
}
//import Csv File Styles 
.importCsvContainer {
    max-width: 1000;
    margin: auto;
    display: flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    .importCsvFile {
        display: flex;
        align-items: center;
        padding: 5em 1.5em 5em 6em;
        background-color: #ffffff;
        border-radius: 10px;
        border: 2px dashed #ffcc00;
    }
    
}
.csvDataHeader {
    margin-bottom:.3125rem;
    font-size: 17px;
}
.csvImportButtonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    .downloadButton, .doneButton  {
        background:#00A651 ;
        color: white;
    }

}
.tablePagination {
    background-color: #ffcb05;
}
//addVideoFom 
.addVideoFormContainer {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .leftBlock {
        width: 40%;
    }
    .rightBlock {
        width: 50%;
    }
}

