body {
    font-size: 16px;
}

.MuiTableCell-root .MuiLink-root{   
    color: #007036;
    text-decoration: none;
}
.MuiTablePagination-root {
    margin-bottom: 0;
}

.MuiInputLabel-root {
    font-size: 1.1rem;
    margin-left: -0.2rem;
}
.MuiChip-deleteIcon {
    font-size: 15px !important;
}
/* .css-9d1esa {
    text-align: center;
} */
.uppy-DragDrop--isDragDropSupported {
    background: #f9fafb;
    border: 2px dashed #ffcc00;
    border-radius:20px;
}
.uppy-DragDrop-arrow {
    fill: #da3925;
    display:none;
}
.uppy-DragDrop-browse {
color: #00A651;
}
/* .css-ki30gk {
    flex-direction: row;
    gap: 5px;
    align-items: start;
    flex-wrap: wrap;
} */

.video-js {
    width: 100%;
}

/* .css-1puh3cy-MuiPaper-root-MuiAppBar-root {
    left: 250px;
    width: calc(100% - 250px);
} */
/* .css-1tfylnl-MuiToolbar-root {
    background-color: #ededed;
    min-height: 60px;
} */

/* .css-14irmdm-MuiPaper-root-MuiCard-root iframe{
    border-radius: 8px;
    overflow: hidden;
    border: none;
} */

.MuiBox-root.css-qeglhl {
    width: 200px;

}
.MuiTableCell-head{
    color: #ffffff;
    background-color: #00A651;
    border-bottom: 1px solid #008842;
}
.MuiTableCell-head .MuiTableSortLabel-root:hover{
    color: #ffffff;
}
.MuiTableCell-head .Mui-active {
    color: white;
}

.MuiTableCell-body{
    background-color: rgba(255, 203, 5, 47%);
    border-bottom: 1px solid rgba(209, 179, 57, 0.671);
}
.MuiTableCell-root .MuiLink-root{   
    color: #007036;
    text-decoration-color: #007036;
}

.MuiTablePagination-root {
    margin-bottom: 0;
}


/* Form Styles */
.MuiFormControl-root {
    border-radius: 6px;
    background-color: #ffffff;
}
/* place holder style */
.MuiAutocomplete-input {
    min-width:fit-content !important;
    flex-grow:unset !important;
    text-overflow: unset !important;
}
