.newDocumentButton,
.addDocumentButton,
.updateButton,.folderButton {
    background: #00A651;
    &:hover {
        background: #00A651;
    }
}
.buttonGroup {
    display: flex;
    gap: 1em;
}
.navigationLinks {
    display: flex;
    gap: 1em;
    cursor: pointer;
    a {
        color:#ED1C24;
        text-decoration-color: #ED1C24;
    }
}
.img {
    max-width: 50% !important;
}
.dataTable {
    max-width: 100%;
    padding: 0;
}
.buttonGroup {
    display: flex;
    align-items: flex-start;
    gap: 1em;
}
.folderView {
    align-items: center;
    .newDocumentButton {
        max-height: 2.5rem;
    }
}
.folderName, .tagName {
    background-color: #ED1C24;
    color:#ffffff;
    margin-left: 5px;
    margin-top: 5px;
    font-size: .65rem;
    &:hover {
        background-color: #ED1C24;
    }
}
.tablePagination {
    background-color: #ffcb05;
}
.showTags {
    background-color: #ffcb03;
    margin-top: 2px;
    margin-bottom: 2px;
    color: red;
    &:hover {
        background-color: #ffcb03;
    }
}
.slectedTag {
    background-color: red;
    color: #ffcb03;
    margin-left: 2px;
    font-weight: 700;

    &:hover {
        background-color: red ;
    }
}
.showTagsContainer {
       
    border: 3px dotted rgb(255, 203, 3);
    padding: 10px;
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 4px;

    &::-webkit-scrollbar {
        width: 8px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #0000004d;
            border-radius:1px;
            }
}
//addDocumentForm 
.addDocumentFormContainer {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .leftBlock {
        width: 40%;
    }
    .rightBlock {
        width: 50%;
    }
}
