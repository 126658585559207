.folderNavigation {
    background-color: #ED1C24;
    color:#ffffff;
    &:hover {
        background-color: #ED1C24;
    }
}
.dataTable {
    max-width: 100%;
    padding: 0;
}
.folderButton {
    background: #00A651;
    &:hover {
        background: #00A651;
    }
}
.folderPageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        background-color:#ffffff !important;
        border-radius: 6px;
    }
}
.navigationLinks {
    display: flex;
    gap: 1em;
    cursor: pointer;
    a {
        color:#ED1C24;
    }
   
}
.buttonGroup {
    display: flex;
gap: 1em;
}
.listedFolders {
    display: flex;
    color: #ED1C24;
    background-color: #ffcb05;
    border-radius: .625rem;
    span {
        font-weight: 600;
    }
    p {
        color: #ED1C24;
    }
}
.listViewFolders {
    text-decoration: none;
    color: #ff0000;
    width: 24.25%;
}
.listItemTextListView {
    span {

        font-size: 12px;
    }
}
.viewButtons {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
}
.CreateFolderButton {
    background-color: #00A651;
    color: #ffffff;
    &:hover {
        background-color: #00A651;
 
    }
}
.button {
    border: none !important;
    padding: 0;
    min-width: 40px;
}
