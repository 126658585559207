.videoGrid {
    max-width: 100% !important;
    .searchForm {
        padding:0 !important;
        font-size: .625rem;
    }
}
.playVideoCard {
    border: 7px solid #ffffff;
    border-bottom: none;
    border-radius:0;
}
.showTags {
    background-color: #ffcb03;
    margin-top: 2px;
    margin-bottom: 2px;
    color: red;
    &:hover {
        background-color: #ffcb03;
    }
}
.slectedTag {
    background-color: red;
    color: #ffcb03;
    margin-left: 2px;
    font-weight: 700;

    &:hover {
        background-color: red ;
    }
}
.showTagsContainer {
       
    border: 3px dotted rgb(255, 203, 3);
    padding: 10px;
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 4px;

    &::-webkit-scrollbar {
        width: 8px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #0000004d;
            border-radius: 1px;
            }
}
    